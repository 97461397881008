import axios from 'axios';

import config from '../config';
import store from '../store';

export default function (payload) {
  return new Promise((resolve = () => {}, reject = () => {}) => {
    if (store.state.auth.token) {
      axios.defaults.headers.common = { Authorization: `bearer ${store.state.auth.token}` };
    }
    axios.defaults.baseURL = config.api.baseUrl;
    if (payload.uploadHandler) {
      axios.post(payload.url, payload.data, {
        baseURL: payload.baseURL,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        maxContentLength: Infinity,
        maxBodyLength: Infinity,
        onUploadProgress: payload.uploadHandler,
      }).then((response) => {
        resolve(response.data);
      }).catch((error) => {
        if (error.response) {
          if (store.state.auth.token && error.response.status === 401) {
            console.log(window.location);
            store.dispatch('auth/doLogout').then(() => {
              window.location = '/auth/login';
            });
            return;
          }
          resolve(error.response.data);
        } else {
          reject(error);
        }
      });
      return;
    }
    payload.maxContentLength = Infinity;
    payload.maxBodyLength = Infinity;
    axios(payload)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        if (error.response) {
          if (store.state.auth.token && error.response.status === 401) {
            console.log(window.location);
            store.dispatch('auth/doLogout').then(() => {
              window.location = '/auth/login';
            });
            return;
          }
          resolve(error.response.data);
        } else {
          reject(error);
        }
      });
  });
}
