import api from '../library/axios';
/* eslint-disable import/no-named-as-default-member */
export default {
  getList({ page = 1, search = '', workspace_ids } = {}) {
    return api({
      method: 'get',
      url: '/api/workspaces',
      params: {
        page,
        search,
        workspace_ids,
      },
    });
  },
  create({ name, ws_product_id }) {
    return api({
      method: 'post',
      url: '/api/workspaces',
      data: {
        name,
        ws_product_id,
      },
    });
  },
  update(id, { name }) {
    return api({
      method: 'patch',
      url: `/api/workspaces/${id}`,
      data: {
        name,
      },
    });
  },
  bot_questions({ id }) {
    return api({
      url: `/api/workspaces/${id}/questions`,
    });
  },
  update_bot_questions({ id, data }) {
    return api({
      method: 'patch',
      url: `/api/workspaces/${id}/questions`,
      data,
    });
  },
  work_hour({ id }) {
    return api({
      url: `/api/workspaces/${id}/work-hour`,
    });
  },
  update_work_hour({ id, data }) {
    return api({
      method: 'patch',
      url: `/api/workspaces/${id}/work-hour`,
      data,
    });
  },
  auto_reminder({ id }) {
    return api({
      url: `/api/workspaces/${id}/auto-reminder`,
    });
  },
  update_auto_reminder({ id, data }) {
    return api({
      method: 'patch',
      url: `/api/workspaces/${id}/auto-reminder`,
      data,
    });
  },
  check_balance({ id }) {
    return api({
      url: `/api/workspaces/${id}/balance`,
    });
  },
};
